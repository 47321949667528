<template>
	<v-sheet class="quotation" id="quotation" style="height: calc(100vh - 88px)">
		<CreateDialog
			:dialog="createDialog"
			v-on:close="
				createDialog = false;
				clearLocalStorage();
			"
			v-on:scroll="hideOnScroll($event)"
		>
			<template v-slot:title>
				<span v-if="!contentLoading">{{ pageTitle() }}</span>
				<span v-if="!contentLoading" class="orange--text text--darken-4 barcode-font-size">
					#{{ quotation.barcode }}</span
				>
			</template>
			<template v-if="scroll_top" v-slot:additional>
				<span>{{ formatMoney(calculated_data.total) }}</span>
			</template>
			<template v-slot:body>
				<template v-if="!barcodeLoading && !contentLoading">
					<v-form
						ref="quotationForm"
						v-model.trim="formValid"
						lazy-validation
						v-on:submit.stop.prevent="updateOrCreate"
					>
						<v-row>
							<v-col md="9">
								<v-row class="px-4">
									<v-col md="2" class="py-0">
										<label for="quotation-title" class="btx-label mt-2 required">Title</label>
									</v-col>
									<v-col md="10" class="py-0">
										<TextInput
											:counter="150"
											:disabled="pageLoading"
											:loading="pageLoading"
											id="quotation-title"
											placeholder="Title"
											v-model="quotation.title"
											:rules="[vrules.required(quotation.title, 'Title')]"
											:class="{
												required: !quotation.title,
											}"
											@change="savetoLocal()"
										>
										</TextInput>
									</v-col>
									<v-col md="2" class="py-0">
										<label for="description" class="btx-label mt-2">Description</label>
									</v-col>
									<v-col md="10" class="py-0">
										<TextAreaInput
											:disabled="pageLoading"
											:loading="pageLoading"
											id="description"
											placeholder="Description"
											v-model="quotation.description"
											@change="savetoLocal()"
										>
										</TextAreaInput>
									</v-col>
									<v-col md="2" class="my-auto py-0">
										<label for="reference" class="btx-label mt-2">
											Reference
											<TooltipQuestion>
												<template v-slot:text>Reference Number Printed on pdf</template>
											</TooltipQuestion>
										</label>
									</v-col>
									<v-col md="4" class="py-0">
										<TextInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="reference"
											placeholder="Reference"
											v-model="quotation.reference"
											@change="savetoLocal()"
										>
										</TextInput>
									</v-col>
									<v-col md="2" class="my-auto text-right">
										<label for="rate_opportunity" class="btx-label">Rate opportunity</label>
									</v-col>
									<v-col md="4" class="py-0">
										<v-rating
											id="rate_opportunity"
											v-model="quotation.rating"
											background-color="orange lighten-3"
											color="orange"
											size="20"
											@change="savetoLocal()"
										></v-rating>
									</v-col>
									<v-col md="2" class="my-auto py-0">
										<label for="reference" class="btx-label mt-2"> Quote number </label>
									</v-col>
									<v-col md="4" class="py-0">
										<TextInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="reference"
											placeholder="quote number"
											v-model="quotation.quotation_number"
											@change="savetoLocal()"
										>
										</TextInput>
									</v-col>
									<v-col md="2" class="my-auto text-right">
										<label for="rate_opportunity" class="btx-label mt-2">Ticket Number</label>
									</v-col>
									<v-col md="4" class="py-0">
										<TextInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="reference"
											placeholder="Ticket Number"
											v-model="quotation.ticket_number"
											@change="savetoLocal()"
										>
										</TextInput>
									</v-col>
									<v-col md="2" class="my-auto">
										<label for="prepared_by" class="btx-label mt-2">Prepared By </label>
									</v-col>
									<v-col md="4" class="py-0">
										<TextInput
											hide-details
											readonly
											:disabled="pageLoading"
											:loading="pageLoading"
											id="Prepared_by"
											placeholder="Prepared By"
											v-model="quotation.prepared_by"
											@change="savetoLocal()"
										>
										</TextInput>
									</v-col>
									<v-col md="2" class="my-auto py-0 text-right">
										<label for="sales-person" class="btx-label mt-2 required">Staff Person </label>
									</v-col>
									<v-col md="4" class="py-0">
										<AutoCompleteInput
											hide-details
											:items="salesPersonList"
											:disabled="pageLoading"
											:loading="pageLoading"
											id="sales-person"
											:rules="[vrules.required(quotation.sales_person, 'Staff Person')]"
											:class="{
												required: !quotation.sales_person,
											}"
											placeholder="Staff Person"
											v-model="quotation.sales_person"
											@change="savetoLocal"
										></AutoCompleteInput>
									</v-col>
									<v-col md="2" class="mt-2 py-0">
										<label for="customer-id" class="btx-label mt-2 required"> Customer </label>
									</v-col>
									<v-col md="10" class="py-0">
										<SelectCustomerSupplier
											v-model="customer"
											:items="customerList"
											:disabled="isCustomerDisabled || pageLoading"
											:update-detail="selectedCustomer"
											type="customer"
											placeholder="Customer"
											input-id="customer-id"
											:rules="[vrules.required(customer.id, 'Customer')]"
											:class="{
												required: !customer.id,
											}"
											@change="
												getRelatedProject($event);
												savetoLocal();
											"
										></SelectCustomerSupplier>
									</v-col>
									<v-col md="2" class="my-auto py-0">
										<label for="Project" class="btx-label mt-2">Project</label>
									</v-col>
									<v-col md="10" class="py-0">
										<AutoCompleteInput
											hide-details
											:items="projectList"
											:disabled="isProjectDisabled || pageLoading"
											:loading="pageLoading"
											id="Project"
											placeholder="Project"
											v-model="quotation.project"
											@change="saveProjectToLocal()"
										></AutoCompleteInput>
									</v-col>
									<template v-if="false">
										<v-col md="3" class="my-auto py-0">
											<label for="internal-refrence" class="btx-label mt-2"></label>
										</v-col>
										<v-col md="9" class="py-0 mt-2">
											<v-row>
												<v-col md="6">
													<label for="">Internal Ref</label>
													<TextInput
														hide-details
														:disabled="pageLoading"
														:loading="pageLoading"
														id="internal-refrence"
														placeholder="Internal Refrence"
														class="mt-0"
														v-model="quotation.internal_refrence"
													></TextInput>
												</v-col>
												<v-col md="6">
													<label for="">Customer Ref</label>
													<TextInput
														hide-details
														:disabled="pageLoading"
														:loading="pageLoading"
														id="customer-refrence"
														placeholder="Customer Refrence"
														class="mt-0"
														v-model="quotation.customer_refrence"
													></TextInput>
												</v-col>
											</v-row>
										</v-col>
									</template>
									<!-- End -->
									<template v-if="false">
										<v-col md="3" class="my-auto py-0">
											<label for="internal-refrence" class="btx-label mt-2">Internal Ref</label>
										</v-col>
										<v-col md="3" class="py-0">
											<TextInput
												hide-details
												:disabled="pageLoading"
												:loading="pageLoading"
												id="internal-refrence"
												placeholder="Internal Refrence"
												v-model="quotation.internal_refrence"
											></TextInput>
										</v-col>
										<v-col md="3" class="my-auto py-0">
											<label for="customer-refrence" class="btx-label mt-2 text-right">Customer Ref</label>
										</v-col>
										<v-col md="3" class="py-0">
											<TextInput
												hide-details
												:disabled="pageLoading"
												:loading="pageLoading"
												id="customer-refrence"
												placeholder="Customer Refrence"
												v-model="quotation.customer_refrence"
											></TextInput>
										</v-col>
									</template>
									<v-col v-if="false" md="3" class="my-auto py-0">
										<label for="payment-term" class="btx-label mt-2 required">Payment Term </label>
									</v-col>
									<v-col v-if="false" md="3" class="py-0">
										<AutoCompleteInput
											hide-details
											:items="paymentTermList"
											:disabled="pageLoading"
											:loading="pageLoading"
											id="payment-term"
											:rules="[vrules.required(quotation.payment_term, 'payment-term')]"
											:class="{
												required: !quotation.payment_term,
											}"
											placeholder="Payment Term"
											v-model="quotation.payment_term"
										></AutoCompleteInput>
									</v-col>
									<v-col v-if="false" md="3" class="my-auto py-0">
										<label for="quotation-date" class="btx-label mt-2">Date </label>
									</v-col>
									<v-col v-if="false" md="3" class="py-0">
										<DatePicker
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="quotation-date"
											v-model="quotation.quotation_date"
										></DatePicker>
									</v-col>
									<v-col v-if="false" md="3" class="my-auto py-0">
										<label for="till__date" class="btx-label mt-2">Till Date </label>
									</v-col>
									<v-col v-if="false" md="3" class="py-0">
										<DatePicker
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="till_date"
											placeholder="Till Date"
											v-model="quotation.till_date"
										>
										</DatePicker>
									</v-col>
								</v-row>
							</v-col>
							<v-col md="3">
								<v-row>
									<v-col md="12" class="py-0 text-right">
										<ImageUpload
											v-if="false"
											can-change
											v-model="quotation.image"
											:page-loading="pageLoading"
										></ImageUpload>
										<div class="py-1 mb-2" v-if="false">
											<span># </span
											><b class="orange--text text--darken-4" style="font-size: 16px"
												>{{ quotation.barcode }}
											</b>
										</div>
										<span class="fw-500 pt-2" style="font-size: 24px">Amount</span><br />
										<div class="blue--text text--darken-4 fw-500 mt-2" style="font-size: 30px">
											{{ formatMoney(calculated_data.total) }}
										</div>
									</v-col>
								</v-row>
							</v-col>
							<!-- ***************************************************
							******** Quotation Line Item Module Start********
						****************************************************-->
							<v-col md="12">
								<div class="middle-header-background d-flex align-items-center justify-content-between">
									<p class="mb-2 px-4">
										Line items<br />
										<span>
											<v-icon small>mdi-progress-question</v-icon> Specify the Line Items for your
											Quotation</span
										>
									</p>
									<!-- <div class="px-4">
										<v-btn
											v-if="uuid"
											tile
											deppressed
											:disabled="hideDownloadBtn || exportBtnLoading"
											:loading="exportBtnLoading"
											color="blue darken-4 white--text"
											@click="exportExcelFile()"
										>
											<v-icon size="16">mdi-file-download</v-icon>
											Download Line Item</v-btn
										>
										<v-btn
											class="ml-3"
											tile
											deppressed
											color="blue darken-4 white--text"
											@click="importExcelFile()"
										>
											<v-icon size="16">mdi-file-excel</v-icon>
											Upload Excel</v-btn
										>
									</div> -->
								</div>
							</v-col>
							<v-col md="12">
								<!-- Quotation Line Item Module Start -->
								<QuotationLineItems
									ref="quotationLineItemsRef"
									:id="quotation.id"
									v-model="line_items"
									:exel-file-uuid="exel_file_uuid"
									v-on:update:total="getCalculatedData($event)"
									v-on:hide:download="hideDownloadBtn = true"
								>
								</QuotationLineItems>

								<QuotationLineItemsNew
									v-if="false"
									ref="quotationLineItemsRef"
									:id="quotation.id"
									v-model="line_items"
									:exel-file-uuid="exel_file_uuid"
									v-on:update:total="getCalculatedData($event)"
									v-on:hide:download="hideDownloadBtn = true"
								>
								</QuotationLineItemsNew>
								<!-- Quotation Line Item Module End -->
							</v-col>
							<v-col md="12">
								<p class="middle-header-background mb-2 px-4">
									Attachments & Other details<br />
									<span>
										<v-icon small>mdi-progress-question</v-icon> Specify the Attachments & Other details for
										your Quotation</span
									>
								</p>
							</v-col>
							<v-col md="9">
								<v-row>
									<v-col md="2" class="py-0">
										<label for="remark" class="btx-label mt-2">Attachments</label>
									</v-col>
									<v-col md="10" class="py-0">
										<FileUpload v-model="quotation.files"></FileUpload>
									</v-col>
									<v-col md="12" class="py-5"> </v-col>
									<v-col md="2" class="py-0">
										<label for="notes" class="btx-label mt-2">Notes</label>
									</v-col>
									<v-col md="10" class="py-0">
										<TextAreaInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="notes"
											placeholder="Notes"
											v-model="quotation.notes"
											@change="savetoLocal()"
										>
										</TextAreaInput>
									</v-col>
									<v-col md="2" class="py-0">
										<label for="terms-and-conditions" class="btx-label mt-2">Terms & Conditions</label>
									</v-col>
									<v-col md="10" class="py-0">
										<SelectTermsCondition
											v-model="terms_and_condition"
											label="Terms & conditions"
											placeholder="Terms & conditions"
											type="quotation"
											append-outer-icon="mdi-cog"
											@change="savetoLocal()"
										></SelectTermsCondition>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-form>
				</template>
				<template v-else>
					<div class="mx-20 my-40 text-center">
						<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
					</div>
				</template>
			</template>
			<template v-slot:action>
				<v-btn
					class="white--text"
					:loading="pageLoading"
					:disabled="pageLoading"
					depressed
					color="blue darken-4"
					tile
					@click="updateOrCreate()"
				>
					Save
				</v-btn>
			</template>
		</CreateDialog>
		<ManagePaymentTerms
			v-if="false"
			:dialog="managePaymentTermsDialog"
			:list="paymentTermList"
			v-on:close="managePaymentTermsDialog = false"
			v-on:success="getCategories"
		></ManagePaymentTerms>
		<QuotationLineItemsCreateUpdate
			v-if="false"
			v-model="line_item"
			:dialog="lineItemDialog"
			:do-update="quotationLineItemUpdate"
			v-on:update="updateCreateLineItems($event)"
			v-on:close="lineItemDialog = false"
		>
		</QuotationLineItemsCreateUpdate>
	</v-sheet>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_ERROR, SET_MESSAGE, GET_PROJUCT_DROPDOWN } from "@/core/services/store/common.module";
import {
	CreateQuotation,
	UpdateQuotation,
	GetQuotation,
	ExportLineItem,
} from "@/core/lib/quotation.lib";
import TextInput from "@/view/components/TextInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import DatePicker from "@/view/components/DatePicker";
import ImageUpload from "@/view/components/ImageUpload";
import ManagePaymentTerms from "@/view/components/Manage-Payment-Terms";
import CreateDialog from "@/view/components/CreateDialog";
import QuotationLineItemsCreateUpdate from "@/view/components/QuotationLineItemsCreateUpdate";
import QuotationLineItems from "@/view/components/QuotationLineItems";
import QuotationLineItemsNew from "@/view/components/QuotationLineItems-New";
import SelectCustomerSupplier from "@/view/components/SelectCustomerSupplier";
import SelectTermsCondition from "@/view/components/SelectTermsCondition";
import FileUpload from "@/view/components/FileUpload";
import TooltipQuestion from "@/view/components/TooltipQuestion";
import { EventBus } from "@/core/event-bus/event.bus";
import "@/core/event-bus/click-outside";
import { filter, map } from "lodash";
import { saveData, getData, deleteData } from "@/core/services/local.service";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
	name: "Quotation-create",
	title: "Create Quotation",
	data() {
		return {
			uuid: null,
			revise: 0,
			duplicate: false,
			exel_file_uuid: null,
			barcode: null,
			scroll_top: false,
			formValid: true,
			createDialog: true,
			pageLoading: false,
			exportBtnLoading: false,
			hideDownloadBtn: false,
			contentLoading: false,
			customer_detail: null,
			terms_and_condition: {
				id: null,
				description: null,
			},
			salePersonList: [],
			customer_billing_address: null,
			customer_contact_person: null,
			managePaymentTermsDialog: false,
			calculated_data: {},
			customer: {
				id: null,
				billing_address_id: null,
				contact_person_id: null,
			},
			selectedCustomer: {
				id: null,
				billing_address_id: null,
				contact_person_id: null,
			},
			salePerson: {
				id: null,
				billing_address_id: null,
				contact_person_id: null,
			},
			selectedSalePerson: {
				id: null,
				billing_address_id: null,
				contact_person_id: null,
			},
			quotation: {
				id: null,
				uuid: null,
				title: null,
				barcode: null,
				description: null,
				quotation_number: null,
				project: null,
				prepared_by: null,
				rating: 3,
				terms_and_conditions_text: null,
				terms_and_conditions: null,
				notes: null,
				files: [],
				revise: 0,
				revised_from: null,
				quotation_date: null,
				property: null,
				billing_address: null,
				contact_persons: null,
				sales_person: null,
				till_date: null,
				customer_billing_address: null,
				customer_contact_person: null,
				admin_remark: null,
				reference: null,
				deadline_condition: null,
				deadline_type: null,
				payment_term: null,
			},
			customerAddressList: [],
			customerPersonList: [],
			salesPersonList: [],
			groupList: [],
			customerList: [],
			allProjectList: [],
			projectList: [],
			propertyList: [],
			paymentTermList: [],
			termsAndConditionList: [{ value: 1, text: "N-20" }],
			deadLineConditionList: [{ value: 1, text: "N-20" }],

			/* Quotation Line Item Start */
			lineItemDialog: false,
			quotationLineItemUpdate: false,
			search_key: null,
			free_text: "This is free Text",
			line_item: {
				id: null,
				uuid: null,
				product: "sample product",
				description: "Type and scrambled",
				quantity: 1,
				quantity_type: null,
				discount: 0,
				discount_value_type: 1,
				discount_value: 0,
				rate: 0.0,
				uom: "cm",
				tax_value: null,
				tax_amount: null,
				sub_total: 0.0,
				total: 0.0,
				product_type: null,
			},
			line_items: [
				{
					isEditable: false,
					id: null,
					uuid: null,
					product: null,
					product_type: null,
					product_barcode: null,
					description: null,
					quantity: 1,
					quantity_type: null,
					discount: 0,
					discount_value_type: 1,
					discount_value: 0,
					rate: 0,
					uom: null,
					tax_value: null,
					tax_amount: null,
					sub_total: 0,
					total: 0,
					order: 0,
				},
			],
			isProjectDisabled: false,
			isCustomerDisabled: false,
		};
	},
	components: {
		TextInput,
		SelectCustomerSupplier,
		DatePicker,
		AutoCompleteInput,
		TextAreaInput,
		ImageUpload,
		ManagePaymentTerms,
		CreateDialog,
		QuotationLineItemsCreateUpdate,
		QuotationLineItems,
		QuotationLineItemsNew,
		FileUpload,
		TooltipQuestion,
		SelectTermsCondition,
	},
	methods: {
		hideOnScroll(scroll) {
			if (scroll > 100) {
				this.scroll_top = true;
			} else {
				this.scroll_top = false;
			}
		},
		importExcelFile() {
			this.$refs.quotationLineItemsRef.openExcelUploadDialog();
		},
		async exportExcelFile() {
			//http://127.0.0.1:8000/api/v1/export-line-items/quotation/a2d04f02-8c50-4301-99bb-6fe1ca0a1ebb
			this.exportBtnLoading = true;
			await ExportLineItem(this.uuid);
			this.exportBtnLoading = false;
		},
		getSubGroup() {
			console.log({ services_category: this.quotation.category });
		},
		async updateOrCreate() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.quotationForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
			}

			if (!_this.$refs.quotationForm.validate()) {
				return false;
			}

			if (!this.calculated_data.isLineItems) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: "Line Items Required" }]);
				return false;
			}

			const attachmenets = map(this.quotation.files, (row) => {
				return { ...row, file: null };
			});

			const formData = {
				id: this.quotation.id,
				uuid: this.quotation.uuid,
				revise: this.quotation.revise,
				revised_from: this.quotation.revised_from,

				barcode: null,
				frontend_uuid: this.exel_file_uuid,
				title: this.quotation.title,
				description: this.quotation.description,
				reference: this.quotation.reference,
				quotation_number: this.quotation.quotation_number,
				ticket_number: this.quotation.ticket_number,
				project: this.quotation.project,
				prepared_by: this.quotation.prepared_by,
				rating: this.quotation.rating,
				sales_person: this.quotation.sales_person,
				customer: this.customer.id,
				// customer_billing_address: this.customer.billing_address_id,
				customer_address: this.customer.billing_address_id,
				customer_contact_person: this.customer.contact_person_id,
				terms_and_conditions_text: this.terms_and_condition.description,
				terms_and_conditions: this.terms_and_condition.id,
				notes: this.quotation.notes,
				files: attachmenets,
				...this.calculated_data,
			};
			try {
				_this.pageLoading = true;
				if (_this.uuid) {
					const { uuid } = await UpdateQuotation(_this.uuid, formData);
					_this.$router.replace({
						name: "quotation-detail",
						params: { uuid },
						query: { t: new Date().getTime() },
					});
					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Quotation has been updated." },
					]);
				} else {
					const { uuid } = await CreateQuotation(formData);
					_this.$router.replace({
						name: "quotation-detail",
						params: { uuid },
						query: { t: new Date().getTime() },
					});
					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Quotation has been created." },
					]);
				}
				// clearing local storage data
				this.clearLocalStorage();
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		clearLocalStorage() {
			deleteData("create_quotation");
			deleteData("create_quotation_project");
			deleteData("local_uuid");
		},
		savetoLocal() {
			if (!this.uuid) {
				const create_quotation = {
					title: this.quotation.title,
					description: this.quotation.description,
					reference: this.quotation.reference,
					quotation_number: this.quotation.quotation_number,
					ticket_number: this.quotation.ticket_number,
					project: this.quotation.project,
					customer: {
						id: this.customer.id,
						contact_person_id: this.customer.contact_person_id,
						billing_address_id: this.billing_address_id,
					},
					sales_person: this.quotation.sales_person,
					notes: this.quotation.notes,
					terms_and_condition: this.terms_and_condition,
				};
				saveData("create_quotation", create_quotation);
			}
		},
		saveProjectToLocal() {
			saveData("create_quotation_project", this.quotation.project);
		},
		getQuotation() {
			this.contentLoading = true;
			this.pageLoading = true;
			GetQuotation(this.uuid)
				.then((data) => {
					this.uuid = data.uuid;
					this.barcode = data.barcode;
					this.quotation = {
						id: data.id,
						uuid: data.uuid,
						title: data.title,
						barcode: data.barcode,
						description: data.description,
						quotation_number: data.quotation_number,
						ticket_number: data.ticket_number,
						reference: data.reference,
						project: data.project && data.project.id,
						prepared_by: data.prepared_by,
						rating: data.rating,
						terms_and_conditions_text: data.terms_and_conditions_text,
						terms_and_conditions: data.terms_and_conditions,
						notes: data.notes,
						sales_person: data.sales_person && data.sales_person.id,
						revise: this.revise && data.revise,
					};
					this.terms_and_condition = {
						id: data.terms_and_conditions,
						description: data.terms_and_conditions_text,
					};
					this.line_items = data.line_items;
					this.selectedCustomer = {
						id: data.customer && data.customer.id && data.customer.id,
						billing_address_id:
							data.customer_address && data.customer_address.id && data.customer_address.id,
						contact_person_id:
							data.customer_contact_person &&
							data.customer_contact_person.id &&
							data.customer_contact_person.id,
					};
					const _calculation = {
						tax_value: data.tax_value,
						taxable_amount: data.taxable_amount,
						tax_amount: data.tax_amount,
						tax_applied: data.tax_applied,
						tax_type: data.tax_type,
						discount_value: data.discount_value,
						discount_value_type: data.discount_value_type,
						discount_amount: data.discount_amount,
						discount_type: data.discount_type,
						adjustment: data.adjustment,
						adjustment_amount: data.adjustment_amount && data.adjustment_amount,
						sub_total: data.sub_total,
						total: data.total,
						show_price: data.show_price,
						uom: data.uom,
						free_text: data.free_text,
						parent_id: data.id,
						isLineItems: this.line_items.length ? true : false,
						line_item_type: data.line_item_type,
					};
					this.contentLoading = false;
					this.$nextTick(() => {
						EventBus.$emit("reload:calculation", _calculation);
					});

					const payload = {
						module: "quotation",
						id: this.quotation.id,
					};
					this.getProjectListiing(payload);

					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Quotation", disabled: true },
						{ text: "Update", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
				})
				.catch((error) => {
					this.backToListing("quotation");
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoading = false;
				});
		},
		getDuplicateQuotation(duplicate_uuid) {
			this.contentLoading = true;
			this.pageLoading = true;
			GetQuotation(duplicate_uuid, this.revise, this.duplicate)
				.then((data) => {
					this.quotation.id = null;
					this.quotation.uuid = null;
					this.quotation.title = data.title;
					this.quotation.description = data.description;
					this.quotation.quotation_number = data.quotation_number;
					this.quotation.ticket_number = data.ticket_number;
					this.quotation.reference = data.reference;
					this.quotation.project = data.project && data.project.id;
					this.quotation.prepared_by = data.prepared_by;
					this.quotation.rating = data.rating;
					this.quotation.terms_and_conditions_text = data.terms_and_conditions_text;
					this.quotation.terms_and_conditions = data.terms_and_conditions;
					this.quotation.notes = data.notes;
					this.quotation.sales_person = data.sales_person && data.sales_person.id;
					this.quotation.revise = this.revise && data.revise;
					this.quotation.revised_from = this.revise && data.id;
					this.terms_and_condition = {
						id: data.terms_and_conditions,
						description: data.terms_and_conditions_text,
					};
					this.line_items = data.line_items;
					this.selectedCustomer = {
						id: data.customer && data.customer.id && data.customer.id,
						billing_address_id:
							data.customer_address && data.customer_address.id && data.customer_address.id,
						contact_person_id:
							data.customer_contact_person &&
							data.customer_contact_person.id &&
							data.customer_contact_person.id,
					};
					const _calculation = {
						tax_value: data.tax_value,
						taxable_amount: data.taxable_amount,
						tax_amount: data.tax_amount,
						tax_applied: data.tax_applied,
						tax_type: data.tax_type,
						discount_value: data.discount_value,
						discount_value_type: data.discount_value_type,
						discount_amount: data.discount_amount,
						discount_type: data.discount_type,
						adjustment: data.adjustment,
						adjustment_amount: data.adjustment_amount && data.adjustment_amount,
						sub_total: data.sub_total,
						total: data.total,
						show_price: data.show_price,
						uom: data.uom,
						free_text: data.free_text,
						parent_id: data.id,
						isLineItems: this.line_items.length ? true : false,
						line_item_type: data.line_item_type,
					};
					this.contentLoading = false;
					this.$nextTick(() => {
						EventBus.$emit("reload:calculation", _calculation);
					});

					const payload = {
						module: "quotation",
						id: this.quotation.id,
					};
					this.getProjectListiing(payload);

					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Quotation", disabled: true },
						{ text: "Update", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
				})
				.catch((error) => {
					this.backToListing("quotation");
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoading = false;
				});
		},
		getCategories(param) {
			this.groupList = param;
		},
		pageTitle() {
			if (this.uuid) {
				return "Update Quotation";
			}
			return "Create New Quotation";
		},
		getCalculatedData(params) {
			this.calculated_data = params;
		},
		shortLineItem(param) {
			// console.log({ param: param });
			this.quotation_line_items = param;
		},
		loadFromLocal() {
			const localSaveData = getData("create_quotation");
			if (localSaveData) {
				this.quotation.title = localSaveData.title;
				this.quotation.description = localSaveData.description;
				this.quotation.quotation_number = localSaveData.quotation_number;
				this.quotation.ticket_number = localSaveData.ticket_number;
				this.quotation.reference = localSaveData.reference;
				this.quotation.project = localSaveData.project;
				this.quotation.sales_person = localSaveData.sales_person;

				// this.quotation.prepared_by = localSaveData.prepared_by;
				// this.quotation.project = localSaveData.project
				this.selectedCustomer = {
					id: localSaveData.customer && localSaveData.customer.id && localSaveData.customer.id,
					contact_person_id:
						localSaveData.contact_person_id &&
						localSaveData.contact_person_id.id &&
						localSaveData.contact_person_id.id,
					billing_address_id:
						localSaveData.billing_address_id &&
						localSaveData.billing_address_id.id &&
						localSaveData.billing_address_id.id,
				};
				this.quotation.customer = localSaveData.customer;
				this.quotation.notes = localSaveData.notes;
				this.terms_and_condition = localSaveData.terms_and_condition;
			}
			const create_quotation_project = getData("create_quotation_project");
			if (create_quotation_project) {
				this.quotation.project = create_quotation_project;
			}
		},
		getProjectListiing(payload) {
			this.$store.dispatch(GET_PROJUCT_DROPDOWN, payload).then(({ projects }) => {
				this.allProjectList = projects;
				/* map(projects, (row) => {
					return { ...row, value: row.id, text: `${row.barcode} - ${row.name}` };
				}); */
			});
		},
		getRelatedProject($event) {
			const customer = $event && $event.id;
			this.projectList = filter(this.allProjectList, (row) => {
				return row.customer == customer;
			});
		},
	},
	beforeMount() {
		const { name, params } = this.$route;
		if (name === "quotation-update") {
			this.uuid = params.uuid;
			if (!this.uuid) {
				this.goBack();
			}
			this.pageLoading = true;
			this.getQuotation();
		} else {
			this.genrateBarcode("quotation", this.$route.query.revise && this.$route.query.uuid).then(
				(output) => {
					this.quotation.barcode = output;
				}
			);
			this.quotation.prepared_by =
				this.authUser && this.authUser.display_name && this.authUser.display_name;
			if (this.$route.query.uuid) {
				const duplicate_uuid = this.$route.query.uuid;
				this.revise = this.$route.query.revise;
				this.duplicate = this.$route.query.duplicate;
				if (!getData("local_uuid")) {
					this.exel_file_uuid = this.genrate_uuid();
					saveData("local_uuid", this.exel_file_uuid);
				}
				//getData("local_uuid");
				this.getDuplicateQuotation(duplicate_uuid);
			}
			const payload = {
				module: "quotation",
				id: null,
			};
			this.getProjectListiing(payload);
		}
	},
	mounted() {
		this.loadFromLocal();
		this.$store.dispatch(SET_LOCAL_DB).then(() => {
			this.customerList = this.localDB("customers", []);
			// this.projectList = this.localDB("projects", []);
			this.paymentTermList = this.localDB("payment_terms", []);
			this.salesPersonList = this.localDB("sales_person", []);
		});
		if (this.$route.query.project) {
			this.isProjectDisabled = true;
			this.quotation.project = this.$route.query.project;
		}
		if (this.$route.query.customer) {
			this.isCustomerDisabled = true;
			this.selectedCustomer.id = this.$route.query.customer;
			this.customer.id = this.$route.query.customer;
		}
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Quotation", disabled: true },
			{ text: "Create", disabled: true },
		]);
	},
	computed: {
		...mapGetters(["errors", "localDB", "authUser"]),
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
<style scoped>
.quotation-line-items th {
	font-weight: 500;
}

.quotation-line-items th,
.quotation-line-items td:not(.borer-none) {
	border-bottom: 1px solid #c7c7c7;
	border-left: 1px solid #c7c7c7;
	height: 40px;
}

.quotation-line-items th:first-child,
.quotation-line-items td:first-child {
	border-left: none !important;
}
</style>
